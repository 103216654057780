<template>
  <div class="clock">
      <span>{{time}}</span>
  </div>
</template>

<script>
import {timeFixs, numFix} from "@/utils/util"
export default {
    name: 'clock',
    mounted(){
        setInterval(() => {
            let date = new Date()
            let str = `${numFix(date.getHours())}:${numFix(date.getMinutes())} ${timeFixs(date.getHours())} ${date.getFullYear()}-${numFix(date.getMonth()+1)}-${date.getDate()}`
            this.time = str
        }, 1000);
    },
    data() {
    return {
      time:'11:22 上午 2020-12-20'
    };
  },
}
</script>

<style lang="less">
.clock {
    background: #FFFFFF;
    opacity: 1;
    border-radius: 30Px;
    display: inline-block;
    span {
        padding: 8Px 30px;
        font-size: 16Px;
        color: #2A2A31;
        display: inline-block;
    }
}
</style>